<template>
  <h1>Site currently under construction. More content coming soon!</h1>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";

@Component
export default class Construction extends Vue {}
</script>
