<template>
  <Layout>
    <Construction></Construction>
  </Layout>
</template>

<script>
import Construction from "../components/Construction.vue";

export default {
  components: { Construction },
  metaInfo: {
    title: "Home"
  }
};
</script>

<style>
.home-links a {
  margin-right: 1rem;
}
</style>
